<script>
export default {
	props: ['isHomepage'],
};
</script>

<template>
	<footer class="landing-footer">
		<div class="container">
			<div class="row">
				<div class="col-lg-3 col-sm-6">
					<div class="mb-4 mb-lg-0">
						<h5 class="mb-3 footer-list-title">Company</h5>
						<ul class="list-unstyled footer-list-menu">
							<li>
								<router-link to="/legal/imprint">Imprint</router-link>
								<a target="_blank" href="https://cftools.software/jobs">Career</a>
							</li>
						</ul>
					</div>
				</div>
				<div class="col-lg-3 col-sm-6">
					<div class="mb-4 mb-lg-0">
						<h5 class="mb-3 footer-list-title">Resources</h5>
						<ul class="list-unstyled footer-list-menu">
							<li>
								<router-link to="/legal/privacy">Privacy Policy</router-link>
							</li>
							<li>
								<router-link to="/legal/terms-of-use">Terms & Conditions</router-link>
							</li>
						</ul>
					</div>
				</div>
				<div class="col-lg-3 col-sm-6">
					<div class="mb-4 mb-lg-0">
						<h5 class="mb-3 footer-list-title">Links</h5>
						<ul class="list-unstyled footer-list-menu">
							<!--
							<li>
								<a href="https://status.cftools.cloud">System status</a>
							</li>
							<li>
								<a href="https://blog.cftools.cloud">Blog</a>
							</li>
							-->
							<li>
								<a href="https://app.cftools.cloud">CFTools Cloud Panel</a>
							</li>
						</ul>
					</div>
				</div>
				<!-- News API -->
				<!--
				<a href="#" class="post">
					<div class="badge badge-soft-success font-size-11 mb-3">title</div>
					<h5 class="post-title">post title</h5>
					<p class="mb-0">
						<i class="bx bx-calendar mr-1"></i> 01 Jan, 1970
					</p>
				</a>
				-->
				<!--
				<div class="col-lg-3 col-sm-6">
					<div class="mb-4 mb-lg-0">
						<h5 class="mb-3 footer-list-title">Latest News</h5>
						<div class="blog-post">
							<i>No news</i>

						</div>
					</div>
				</div>
				-->
			</div>
			<!-- end row -->

			<hr class="footer-border my-5"/>

			<div class="row">
				<div class="col-lg-8">
					<div class="mb-4">
						<img src="https://cdn.cftools.de/brand/tools/white-125x.png" alt/>
					</div>

					<p class="mb-2">{{ new Date().getFullYear() }} © CFTools™ Software GmbH. All rights reserved.</p>
					<p>All game content and trademarks are the property of their respective owners. </p>
					<p>
						This website is not affiliated or authorized by Bohemia Interactive a.s. Bohemia Interactive, ARMA,
						DAYZ and all associated logos and designs are trademarks or registered trademarks of Bohemia Interactive
						a.s.
					</p>
					<!-- <p>E-Mail: <span style="margin-right: 0.5px;">info</span><label style="margin-left: 0.38px; margin-right: 0.5px;">@</label><span style="margin-left: 1.38px;">cftools.cloud</span></p> -->
				</div>
			</div>
		</div>
		<!-- end container -->
	</footer>
</template>